<template>
    <fragment>
        <li class="menu-section">
            <h4 class="menu-text">Quản lý tài chính</h4>
        </li>
        <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_TAI_CHINH_2)
||currentUser.user.permission.includes(permission.NAP_RUT_TANG_QUA)
||currentUser.user.permission.includes(permission.EDUTALK_THU_TIEN)
||currentUser.user.permission.includes(permission.HOP_DONG_UNG)
||currentUser.user.permission.includes(permission.NAP_RUT_197)
||currentUser.user.permission.includes(permission.KHOA_HOC_DANG_KY)
||currentUser.user.permission.includes(permission.HUY_TIEU_KHOA_HOC)
||currentUser.user.permission.includes(permission.XU_LY_SO_DU_TAI_KHOAN)
||currentUser.user.permission.includes(permission.QUAN_LY_CHUYEN_NHUONG)
||currentUser.user.permission.includes(permission.DUYET_TRA_GOP)
||currentUser.user.permission.includes(permission.THONG_TIN_CITIME)
||currentUser.user.permission.includes(permission.GIA_VON)
||currentUser.user.permission.includes(permission.QUAN_LY_VON_GOP_THUC_TE)
||currentUser.user.permission.includes(permission.CHI_VAN_PHONG)
"
>
            <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon fas fa-money-bill-wave"></i>
                <span class="menu-text">Quản lý</span>
                <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
                <ul class="menu-subnav">
                    <router-link :to="{ name : 'customer-payments'}"
                                 v-slot="{ href, navigate, isActive, isExactActive }">
                        <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                                v-if="currentUser.user.permission.includes(permission.NAP_RUT_TANG_QUA)"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Nạp-Rút-Tặng quà</span>
                            </a>
                        </li>
                    </router-link>
                    <router-link :to="{ name : 'edutalk-payment'}"
                                 v-slot="{ href, navigate, isActive, isExactActive }">
                        <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                                v-if="currentUser.user.permission.includes(permission.EDUTALK_THU_TIEN)"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Edutalk thu tiền</span>
                            </a>
                        </li>
                    </router-link>
                    <router-link :to="{ name : 'move-amount'}"
                                 v-slot="{ href, navigate, isActive, isExactActive }">
                        <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                                v-if="currentUser.user.permission.includes(permission.HOP_DONG_UNG)"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Hợp đồng ứng</span>
                            </a>
                        </li>
                    </router-link>
                    <router-link :to="{ name : 'cost-price'}"
                                 v-slot="{ href, navigate, isActive, isExactActive }">
                        <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                                v-if="currentUser.user.permission.includes(permission.GIA_VON)"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Giá vốn</span>
                            </a>
                        </li>
                    </router-link>
                    <router-link :to="{ name : 'nap-rut-19-7'}"
                                 v-slot="{ href, navigate, isActive, isExactActive }">
                        <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                                v-if="currentUser.user.permission.includes(permission.NAP_RUT_197)"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Nạp, rút tiền trước 19-7 </span>
                            </a>
                        </li>
                    </router-link>
                    <router-link :to="{ name : 'tieu-so-du'}"
                               v-slot="{ href, navigate, isActive, isExactActive }">
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        v-if="currentUser.user.permission.includes(permission.XU_LY_SO_DU_TAI_KHOAN)"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Xử lý số dư tài khoản</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link :to="{ name : 'tai-chinh-theo-khoa-hoc'}"
                               v-slot="{ href, navigate, isActive, isExactActive }">
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        v-if="currentUser.user.permission.includes(permission.KHOA_HOC_DANG_KY)"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Khóa học đăng ký</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link :to="{ name : 'huy-tieu-khoa-hoc'}"
                               v-slot="{ href, navigate, isActive, isExactActive }">
                    <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                            v-if="currentUser.user.permission.includes(permission.HUY_TIEU_KHOA_HOC)"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Hủy tiêu khóa học không có HĐ</span>
                      </a>
                    </li>
                  </router-link>
                    <router-link :to="{ name : 'course-transfers-list'}"
                                 v-slot="{ href, navigate, isActive, isExactActive }">
                      <li
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                          class="menu-item"
                          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                          v-if="currentUser.user.permission.includes(permission.QUAN_LY_CHUYEN_NHUONG)"
                      >
                        <a :href="href" class="menu-link" @click="navigate">
                          <i class="menu-bullet menu-bullet-dot"><span></span></i>
                          <span class="menu-text">Chuyển nhượng khóa học</span>
                        </a>
                      </li>
                    </router-link>

                  <router-link :to="{ name : 'refund-list'}"
                               v-slot="{ href, navigate, isActive, isExactActive }">
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        v-if="currentUser.user.permission.includes(permission.CHUONG_TRINH_HOAN_TIEN)"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Chương trình hoàn tiền</span>
                      </a>
                    </li>
                  </router-link>

                     <router-link :to="{ name : 'installment-information-list'}"
                                 v-slot="{ href, navigate, isActive, isExactActive }">
                      <li
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                          class="menu-item"
                          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                          v-if="currentUser.user.permission.includes(permission.DUYET_TRA_GOP)"
                      >
                        <a :href="href" class="menu-link" @click="navigate">
                          <i class="menu-bullet menu-bullet-dot"><span></span></i>
                          <span class="menu-text">Duyệt trả góp</span>
                        </a>
                      </li>
                    </router-link>
                  <router-link :to="{ name : 'citime-index'}"
                               v-slot="{ href, navigate, isActive, isExactActive }">
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        v-if="currentUser.user.permission.includes(permission.THONG_TIN_CITIME)"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Thông tin Citime</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link :to="{ name : 'book-costs-list'}"
                               v-slot="{ href, navigate, isActive, isExactActive }">
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        v-if="currentUser.user.permission.includes(permission.QUAN_LY_CHI_PHI_SACH)"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Chi phí sách</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link :to="{ name : 'box-costs-list'}"
                               v-slot="{ href, navigate, isActive, isExactActive }">
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        v-if="currentUser.user.permission.includes(permission.QUAN_LY_CHI_PHI_BOX)"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Chi phí box, admin</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link :to="{ name : 'list-finance-transaction'}"
                               v-slot="{ href, navigate, isActive, isExactActive }">
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        v-if="currentUser.user.permission.includes(permission.QUAN_LY_VON_GOP_THUC_TE)"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Quản lý vốn góp thực tế</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link :to="{ name : 'finance-actual-contributeds'}"
                               v-slot="{ href, navigate, isActive, isExactActive }">
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        v-if="currentUser.user.permission.includes(permission.CHI_VAN_PHONG)"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Chi văn phòng</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
            </div>

        </li>
        <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                v-if="currentUser.user.permission.includes(permission.BAO_CAO_TAI_CHINH)
||currentUser.user.permission.includes(permission.KHACH_HANG_DONG_TIEN)
||currentUser.user.permission.includes(permission.QUYET_TOAN_TRUNG_TAM)
||currentUser.user.permission.includes(permission.CONG_NO_KHACH_HANG)
||currentUser.user.permission.includes(permission.DS_KH_DONG_TIEN)"
        >
            <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon fas fa-tasks"></i>
                <span class="menu-text">Báo cáo</span>
                <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
                <ul class="menu-subnav">
                    <router-link :to="{ name : 'edutalk-center-payment'}"
                                 v-slot="{ href, navigate, isActive, isExactActive }">
                        <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                                v-if="currentUser.user.permission.includes(permission.QUYET_TOAN_TRUNG_TAM)"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Quyết toán trung tâm</span>
                            </a>
                        </li>
                    </router-link>
                    <router-link :to="{ name : 'payment-log'}"
                                 v-slot="{ href, navigate, isActive, isExactActive }">
                        <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                                v-if="currentUser.user.permission.includes(permission.KHACH_HANG_DONG_TIEN)
||currentUser.user.permission.includes(permission.DS_KH_DONG_TIEN)"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Khách hàng đóng tiền</span>
                            </a>
                        </li>
                    </router-link>
                  <router-link :to="{ name : 'cong-no-khach-hang'}"
                               v-slot="{ href, navigate, isActive, isExactActive }">
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        v-if="currentUser.user.permission.includes(permission.CONG_NO_KHACH_HANG)"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Công nợ khách hàng</span>
                      </a>
                    </li>
                  </router-link>
                    <router-link :to="{ name : 'finance-branch-report-index'}"
                                 v-slot="{ href, navigate, isActive, isExactActive }">
                        <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                            v-if="currentUser.user.permission.includes(permission.BAO_CAO_TAI_CHINH_VP)"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Báo cáo tài chính văn phòng</span>
                            </a>
                        </li>
                    </router-link>
                  <router-link :to="{ name : 'NetRevenueExport'}"
                               v-slot="{ href, navigate, isActive, isExactActive }">
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        v-if="currentUser.user.permission.includes(permission.QUAN_LY_DOANH_THU_THUAN)"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Doanh thu thuần</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link :to="{ name : 'FinanceShareholderIndex'}"
                               v-slot="{ href, navigate, isActive, isExactActive }">
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        v-if="currentUser.user.permission.includes(permission.BAO_CAO_CO_DONG)"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Báo cáo cổ đông</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
            </div>
        </li>
    </fragment>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: "QuanLyTaiChinh",
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters(["currentUser"]),
        },

        methods: {
            hasActiveChildren(match) {
                return this.$route["path"].indexOf(match) !== -1;
            }
        }
    };
</script>
