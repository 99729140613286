<template>
  <fragment>
    <li class="menu-section">
      <h4 class="menu-text">Hệ thống cài đặt</h4>
    </li>
      <router-link :to="{ name : 'database-cluster'}" class="menu-link menu-toggle"
                   v-slot="{ href, navigate, isActive, isExactActive }"
                   v-if="currentUser.user.permission.includes(permission.QUAN_LY_HE_THONG_CAI_DAT)
||currentUser.user.permission.includes(permission.DATABASE_CLUSTER)">
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
          >

              <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon fas fa-database"></i>
                  <span class="menu-text">Database Cluster</span>
              </a>

          </li>
      </router-link>
  </fragment>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Setting",
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
