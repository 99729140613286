<template>
    <fragment>
        <li class="menu-section">
            <h4 class="menu-text">Quản lý hệ thống thông tin</h4>
        </li>
        <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
        >
            <a href="#" class="menu-link">
                <i class="menu-icon fas fa-info"></i>
                <span class="menu-text">Thông tin nội bộ</span>
            </a>


        </li>
        <router-link :to="{ name : 'information-index'}"
                     v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon fas fa-bell"></i>
                    <span class="menu-text">Thông báo nội bộ</span>
                </a>
            </li>
        </router-link>
        <router-link :to="{ name : 'NotificationList'}" class="menu-link menu-toggle"
                     v-slot="{ href, navigate, isActive, isExactActive }">
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
              v-if="currentUser.user.permission.includes(permission.QUAN_LY_HE_THONG_THONG_TIN)
              && currentUser.user.permission.includes(permission.QUAN_LY_NOTIFICATION)"
          >

            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon el-icon-bell"></i>
              <span class="menu-text">Notification</span>
            </a>

          </li>
        </router-link>
        <router-link :to="{ name : 'handbook-index'}"
                     v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon fas fa-book"></i>
                    <span class="menu-text">Cẩm nang vận hành</span>
                </a>
            </li>
        </router-link>
        <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
        >
            <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon fas fa-book-open"></i>
                <span class="menu-text">Chính sách</span>
                <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
                <ul class="menu-subnav">
                    <router-link :to="{ name : 'mechanism-basis-index'}"
                                 v-slot="{ href, navigate, isActive, isExactActive }">
                        <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Khối cơ sở</span>
                            </a>
                        </li>
                    </router-link>
                    <router-link :to="{ name : 'mechanism-index'}"
                                 v-slot="{ href, navigate, isActive, isExactActive }">
                        <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Trụ sở tổng</span>
                            </a>
                        </li>
                    </router-link>
                </ul>
            </div>
        </li>
    </fragment>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: "QuanLyHeThongThongTin",
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters(["currentUser"]),
        },

        methods: {
            hasActiveChildren(match) {
                return this.$route["path"].indexOf(match) !== -1;
            }
        }
    };
</script>