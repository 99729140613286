<template>
    <fragment>
        <li class="menu-section">
            <h4 class="menu-text">Quản lý tài khoản</h4>
        </li>
        <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
        >
            <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon fas fa-user-shield"></i>
                <span class="menu-text">Tài khoản</span>
                <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
                <ul class="menu-subnav">
                  <router-link
                      :to="{ name : 'account'}"
                      v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Danh sách tài khoản</span>
                      </a>
                    </li>
                  </router-link>
                    <router-link
                            :to="{ name : 'account-type-index'}"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                    >
                        <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item menu-item-submenu"
                                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Loại tài khoản</span>
                            </a>

                        </li>
                    </router-link>
                    <router-link
                            :to="{ name : 'permission-list'}"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                    >
                        <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item menu-item-submenu"
                                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Quyền</span>
                            </a>
                        </li>
                    </router-link>
                    <router-link
                            :to="{ name : 'role-list'}"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                    >
                        <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item menu-item-submenu"
                                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                        >
                            <a :href="href" class="menu-link" @click="navigate">
                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                <span class="menu-text">Nhóm quyền</span>
                            </a>
                        </li>
                    </router-link>
                    <router-link
                      :to="{ name : 'ManagerBot'}"
                      v-slot="{ href, navigate, isActive, isExactActive }"
                      v-if="currentUser.user.permission.includes(permission.QUAN_LY_BOT)"
                  >
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item menu-item-submenu"
                        :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot"><span></span></i>
                        <span class="menu-text">Quản lý BOT</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
            </div>
        </li>

    </fragment>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: "QuanLyNhanSu",
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters(["currentUser"]),
        },

        methods: {
            hasActiveChildren(match) {
                return this.$route["path"].indexOf(match) !== -1;
            }
        }
    };
</script>
