<template>
  <fragment>
    <li class="menu-section" v-bind:class="{
        'menu-item-open': hasActiveChildren('/centers')
        || hasActiveChildren('/branches')
        || hasActiveChildren('/courses')
        || hasActiveChildren('/area')
      }">
      <h4 class="menu-text">Quản lý sản xuất</h4>
    </li>
    <router-link :to="{ name : 'report-error-index'}" class="menu-link menu-toggle"
                 v-slot="{ href, navigate, isActive, isExactActive }"
                 v-if="currentUser.user.permission.includes(permission.BANG_BAO_LOI)"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
      >

        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-bug"></i>
          <span class="menu-text">Bảng báo lỗi</span>
        </a>
      </li>
    </router-link>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="currentUser.user.permission.includes(permission.QUAN_LY_DON_VI_SX)"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-indent"></i>
        <span class="menu-text">Quản lý đơn vị SX</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
              :to="{ name : 'center-educenter'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Trung tâm</span>
              </a>
            </li>
          </router-link>
<!--          <router-link-->
<!--              :to="{ name : 'user-list'}"-->
<!--              v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--          >-->
<!--            <li-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"-->
<!--            >-->
<!--              <a :href="href" class="menu-link" @click="navigate">-->
<!--                <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
<!--                <span class="menu-text">Học viên</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->
          <router-link
              :to="{ name : 'teacher-list'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Giảng viên</span>
              </a>
            </li>
          </router-link>
<!--          <router-link :to="{ name : 'course-type-list'}"-->
<!--                       v-slot="{ href, navigate, isActive, isExactActive }">-->
<!--            <li-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"-->
<!--            >-->
<!--              <a :href="href" class="menu-link" @click="navigate">-->
<!--                <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
<!--                <span class="menu-text">Loại khóa học</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->
          <router-link :to="{ name : 'course-level-list'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Trình độ</span>
              </a>
            </li>
          </router-link>
<!--          <router-link :to="{ name : 'course-index'}"-->
<!--                       v-slot="{ href, navigate, isActive, isExactActive }">-->
<!--            <li-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"-->
<!--            >-->
<!--              <a :href="href" class="menu-link" @click="navigate">-->
<!--                <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
<!--                <span class="menu-text">Khóa học</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->
          <router-link
              :to="{ name : 'branch-index'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Chi nhánh</span>
              </a>
            </li>
          </router-link>

<!--          <router-link :to="{ name : 'class-index'}"-->
<!--                       v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--          >-->
<!--            <li-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"-->
<!--            >-->
<!--              <a :href="href" class="menu-link" @click="navigate">-->
<!--                <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
<!--                <span class="menu-text">Lớp học</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->
<!--          <router-link :to="{ name : 'student-all'}"-->
<!--                       v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--          >-->
<!--            <li-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"-->
<!--            >-->
<!--              <a :href="href" class="menu-link" @click="navigate">-->
<!--                <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
<!--                <span class="menu-text">Tổng học viên</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->
<!--          <router-link :to="{ name : 'room-index'}"-->
<!--                       v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--          >-->
<!--            <li-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"-->
<!--            >-->
<!--              <a :href="href" class="menu-link" @click="navigate">-->
<!--                <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
<!--                <span class="menu-text">Phòng học</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->
<!--          <router-link :to="{ name : 'room-empty'}"-->
<!--                       v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--          >-->
<!--            <li-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"-->
<!--            >-->
<!--              <a :href="href" class="menu-link" @click="navigate">-->
<!--                <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
<!--                <span class="menu-text">Phòng học trống</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->

<!--          <router-link :to="{ name : 'review-list'}"-->
<!--                       v-slot="{ href, navigate, isActive, isExactActive }">-->
<!--            <li-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"-->
<!--            >-->
<!--              <a :href="href" class="menu-link" @click="navigate">-->
<!--                <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
<!--                <span class="menu-text">Rating</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->
<!--          <router-link :to="{ name : 'promotion-index'}"-->
<!--                       v-slot="{ href, navigate, isActive, isExactActive }">-->
<!--            <li-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--            >-->
<!--              <a :href="href" class="menu-link">-->
<!--                <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
<!--                <span class="menu-text">Mã khuyến mại</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->
<!--          <router-link :to="{ name : 'schedule-runing'}"-->
<!--                       v-slot="{ href, navigate, isActive, isExactActive }">-->
<!--            <li-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--            >-->
<!--              <a :href="href" class="menu-link">-->
<!--                <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
<!--                <span class="menu-text">Lịch trình</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->
          <router-link :to="{ name : 'manage-school-schedules'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Lịch học</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="currentUser.user.permission.includes(permission.QUAN_LY_DAY_HOC)
              ||  currentUser.user.permission.includes(permission.TEST_DAU_VAO)
              ||  currentUser.user.permission.includes(permission.QUAN_LY_TESTER)
              ||  currentUser.user.permission.includes(permission.BUOI_KIEM_TRA)
             ||  currentUser.user.permission.includes(permission.SU_KIEN)
             ||  currentUser.user.permission.includes(permission.WORKSHOP_CLUB)
             ||  currentUser.user.permission.includes(permission.DUYET_NGHI_BUOI_HOC)
             ||  currentUser.user.permission.includes(permission.QUAN_LY_LO_TRINH)
             || currentUser.user.permission.includes(permission.SUA_LOI_DAY_HOC)
             || currentUser.user.permission.includes(permission.QUAN_LY_TAC_VU_DIEU_PHOI_LOP_HOC)
             || currentUser.user.permission.includes(permission.XEM_DANH_SACH_TAC_VU_DIEU_PHOI_LOP_HOC)
             || currentUser.user.permission.includes(permission.GIAI_DAU)
             "
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-solid fa-graduation-cap"></i>
        <span class="menu-text">Quản lý dạy học</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link :to="{ name : 'incomes-list'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.TEST_DAU_VAO)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Test đầu vào</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'tester-list'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_TESTER)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Tester</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'event-list'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.SU_KIEN)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Sự kiện</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'workshop-club-list'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.WORKSHOP_CLUB)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Workshop & Club</span>
              </a>
            </li>
          </router-link>

          <router-link :to="{ name : 'off-lesson-list'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.DUYET_NGHI_BUOI_HOC)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Duyệt buổi nghỉ học </span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'schedule-course-list'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_LO_TRINH)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Quản lý lộ trình </span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'exam-index'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.BUOI_KIEM_TRA)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Buổi kiểm tra</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'manage-error'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.SUA_LOI_DAY_HOC)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Sửa lỗi dạy học</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'tournamentList'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.GIAI_DAU)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Đấu trường</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="currentUser.user.permission.includes(permission.QUAN_LY_ADMIN) ||
              currentUser.user.permission.includes(permission.NHAN_SU_ADMIN) ||
              currentUser.user.permission.includes(permission.LICH_TRUC_TEST_DAU_VAO) ||
              currentUser.user.permission.includes(permission.QUAN_LY_TAC_VU) ||
              currentUser.user.permission.includes(permission.REPORT_CA_TEST) ||
              currentUser.user.permission.includes(permission.TAC_VU_ADMIN_CA_NHAN)"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-solid fas fa-bookmark"></i>
        <span class="menu-text">Quản lý admin</span>
        <i class="menu-arrow text-white"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link :to="{ name : 'tester-list'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.NHAN_SU_ADMIN)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Nhân sự admin</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'task-manager'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_TAC_VU)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Quản lý tác vụ</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'entrance-test-schedule'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.LICH_TRUC_TEST_DAU_VAO)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Lịch trực test đầu vào</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'report-test'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.REPORT_CA_TEST)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Report ca test</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'task-manager-personal'}" class="menu-link menu-toggle"
                       v-slot="{ href, navigate, isActive, isExactActive }"
                       v-if="currentUser.user.permission.includes(permission.TAC_VU_ADMIN_CA_NHAN)"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
            >

              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Tác vụ cá nhân</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="
              currentUser.user.permission.includes(permission.TAC_VU_XEP_LOP_CA_NHAN) ||
              currentUser.user.permission.includes(permission.QUAN_LY_TAC_VU_DIEU_PHOI_LOP_HOC) ||
              currentUser.user.permission.includes(permission.XEM_DANH_SACH_TAC_VU_DIEU_PHOI_LOP_HOC)"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-users"></i>
        <span class="menu-text">Điều phối lớp học</span>
        <i class="menu-arrow text-white"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link :to="{ name : 'TaskManagementList'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_TAC_VU_DIEU_PHOI_LOP_HOC) ||
                      currentUser.user.permission.includes(permission.XEM_DANH_SACH_TAC_VU_DIEU_PHOI_LOP_HOC)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Quản lý tác vụ</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'TaskArrangePersonal'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.TAC_VU_XEP_LOP_CA_NHAN)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Tác vụ cá nhân</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <router-link :to="{ name : 'task-manager-personal'}" class="menu-link menu-toggle"
                 v-slot="{ href, navigate, isActive, isExactActive }"
                 v-if="currentUser.user.permission.includes(permission.DANH_SACH_TEST_DAU_VAO)"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
      >

    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="currentUser.user.permission.includes(permission.RD_SAN_PHAM)"
    >
      <a href="#" class="menu-link">
        <i class="menu-icon far fa-gem"></i>
        <span class="menu-text">R&D Sản phẩm</span>
      </a>
    </li>
    </router-link>
  </fragment>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "QuanLyChuoiCungUng",
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
