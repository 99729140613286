<template>
    <ul class="menu-nav">
        <router-link
                to="/dashboard"
                v-slot="{ href, navigate, isActive, isExactActive }"
                v-if="[role_auth.ADMIN].includes(currentUser.user.role)||true"
        >
            <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-architecture-and-city"></i>
                    <span class="menu-text">Trang chủ</span>
                </a>
            </li>
        </router-link>

        <quan-ly-nhan-su
            v-if="currentUser.user.permission.includes(permission.QUAN_LY_NHAN_SU)
             || currentUser.user.permission.includes(permission.KHOI_CO_SO)
             || currentUser.user.permission.includes(permission.TRU_SO_TONG)
              || currentUser.user.permission.includes(permission.QUAN_LY_NHAN_SU_KHOI_CO_SO)
               || currentUser.user.permission.includes(permission.BAO_CAO)
               || currentUser.user.permission.includes(permission.XEM_DANH_SACH_NHAN_SU_CO_SO)
               || currentUser.user.permission.includes(permission.DIEU_CHUYEN_NHAN_SU)
               || currentUser.user.permission.includes(permission.SALE_LEN_CAP)
               || currentUser.user.permission.includes(permission.SALE_XUONG_CAP)
               || currentUser.user.permission.includes(permission.PHIEU_LUONG)
               || currentUser.user.permission.includes(permission.DANH_SACH_DONG_TIEN)
               || currentUser.user.permission.includes(permission.WEBSITE_TUYEN_DUNG)
               || currentUser.user.permission.includes(permission.SU_KIEN_NOI_BO)
               || currentUser.user.permission.includes(permission.QUAN_LY_DAO_TAO)
               || currentUser.user.permission.includes(permission.BAO_CAO_DAO_TAO)
               || currentUser.user.permission.includes(permission.QUAN_LY_DAO_TAO_UNG_VIEN_GIANG_VIEN)
               || currentUser.user.permission.includes(permission.QUAN_LY_DON_VI_TRU_SO_TONG)
               || currentUser.user.permission.includes(permission.NHAN_SU_CHAM_CONG)
               || currentUser.user.permission.includes(permission.QUAN_LY_NHAN_SU_TRU_SO_TONG)
               || currentUser.user.permission.includes(permission.DON_DE_XUAT)
               || currentUser.user.permission.includes(permission.CO_SO_VAT_CHAT_TRU_SO_TONG)
               || currentUser.user.permission.includes(permission.PHAN_QUYEN_CHUONG_TRINH)
               || currentUser.user.permission.includes(permission.QUAN_LY_CHAM_CONG)
               || currentUser.user.permission.includes(permission.CHUONG_TRINH_TVV_DINH_KY)
               || currentUser.user.permission.includes(permission.XEM_DANH_SACH_DONG_TIEN)
               || currentUser.user.permission.includes(permission.CHUONG_TRINH_VINH_DANH)
               || currentUser.user.permission.includes(permission.QUAN_LY_VON_GOP_THUC_TE)
               || currentUser.user.permission.includes(permission.APP_GAME_CAMBRIDGE)
               || currentUser.user.permission.includes(permission.CHI_VAN_PHONG)"
        ></quan-ly-nhan-su>
        <quan-ly-kinh-doanh
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_KINH_DOANH)
             || currentUser.user.permission.includes(permission.QUAN_LY_KENH_CTV)
             || currentUser.user.permission.includes(permission.BAO_CAO_KENH_CTV)
             || currentUser.user.permission.includes(permission.QUAN_LY_KENH_WEBSITE)
             || currentUser.user.permission.includes(permission.QUAN_LY_GIAO_DIEN)
             || currentUser.user.permission.includes(permission.DANH_SACH_TIN_TUC)
             || currentUser.user.permission.includes(permission.DANH_SACH_SU_KIEN)
             || currentUser.user.permission.includes(permission.DANH_SACH_PAGE)
             || currentUser.user.permission.includes(permission.DANH_SACH_PAGE)
             || currentUser.user.permission.includes(permission.CHUONG_TRINH_QUAY_THUONG)
             || currentUser.user.permission.includes(permission.QUAN_LY_HE_SO_KINH_DOANH)
            "
        ></quan-ly-kinh-doanh>
        <quan-ly-tai-chinh
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_TAI_CHINH)
             || currentUser.user.permission.includes(permission.QUAN_LY_TAI_CHINH_2)
             || currentUser.user.permission.includes(permission.KHOA_HOC_DANG_KY)
             ||  currentUser.user.permission.includes(permission.NAP_RUT_TANG_QUA)
             ||  currentUser.user.permission.includes(permission.EDUTALK_THU_TIEN)
             ||  currentUser.user.permission.includes(permission.HOP_DONG_UNG)
             ||  currentUser.user.permission.includes(permission.NAP_RUT_197)
             ||  currentUser.user.permission.includes(permission.BAO_CAO_TAI_CHINH)
             ||  currentUser.user.permission.includes(permission.KHACH_HANG_DONG_TIEN)
             ||  currentUser.user.permission.includes(permission.DS_KH_DONG_TIEN)
             ||  currentUser.user.permission.includes(permission.QUYET_TOAN_TRUNG_TAM)
             ||  currentUser.user.permission.includes(permission.CONG_NO_KHACH_HANG)
             ||  currentUser.user.permission.includes(permission.XU_LY_SO_DU_TAI_KHOAN)
             ||  currentUser.user.permission.includes(permission.QUAN_LY_CHUYEN_NHUONG)
             ||  currentUser.user.permission.includes(permission.QUAN_LY_DOANH_THU_THUAN)
             ||  currentUser.user.permission.includes(permission.QUAN_LY_CHI_PHI_SACH)
             ||  currentUser.user.permission.includes(permission.QUAN_LY_CHI_PHI_BOX)
            "
        ></quan-ly-tai-chinh>
        <quan-ly-chuoi-cung-ung
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_SAN_XUAT)
             ||  currentUser.user.permission.includes(permission.QUAN_LY_DON_VI_SX)
             ||  currentUser.user.permission.includes(permission.TEST_DAU_VAO)
             ||  currentUser.user.permission.includes(permission.SU_KIEN)
             ||  currentUser.user.permission.includes(permission.WORKSHOP_CLUB)
             ||  currentUser.user.permission.includes(permission.DUYET_NGHI_BUOI_HOC)
             ||  currentUser.user.permission.includes(permission.QUAN_LY_DAY_HOC)
             ||  currentUser.user.permission.includes(permission.BANG_BAO_LOI)
             ||  currentUser.user.permission.includes(permission.SUA_LOI_DAY_HOC)
             ||  currentUser.user.permission.includes(permission.BUOI_KIEM_TRA)
             ||  currentUser.user.permission.includes(permission.TAC_VU_ADMIN_CA_NHAN)
             ||  currentUser.user.permission.includes(permission.LICH_TRUC_TEST_DAU_VAO)
             ||  currentUser.user.permission.includes(permission.QUAN_LY_TAC_VU)
             ||  currentUser.user.permission.includes(permission.NHAN_SU_ADMIN)
             ||  currentUser.user.permission.includes(permission.QUAN_LY_LO_TRINH)
             ||  currentUser.user.permission.includes(permission.REPORT_CA_TEST)
            "
        ></quan-ly-chuoi-cung-ung>
        <quan-ly-he-thong-thong-tin
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_HE_THONG_THONG_TIN)
            "
        ></quan-ly-he-thong-thong-tin>
        <quan-ly-du-lieu
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_DU_LIEU)
            "
        ></quan-ly-du-lieu>
        <CRM
                v-if="currentUser.user.permission.includes(permission.CRM)
             ||  currentUser.user.permission.includes(permission.KHACH_HANG)
             ||  currentUser.user.permission.includes(permission.DS_KHACH_HANG)
             || currentUser.user.permission.includes(permission.DS_HD)
             || currentUser.user.permission.includes(permission.DS_HD_2)
             || currentUser.user.permission.includes(permission.XEM_DS_HD)
             || currentUser.user.permission.includes(permission.CHI_TIET_HD)
             || currentUser.user.permission.includes(permission.SUA_HD)
             || currentUser.user.permission.includes(permission.XOA_HD)
             || currentUser.user.permission.includes(permission.TAO_HD)
             || currentUser.user.permission.includes(permission.XU_LY_PHAN_HOI)
             || currentUser.user.permission.includes(permission.PHONG_BAN_NHAP_GIAI_PHAP)
             || currentUser.user.permission.includes(permission.LICH_SU_CUOC_GOI)
             || currentUser.user.permission.includes(permission.BAO_CAO_CUOC_GOI)
            "
        ></CRM>
        <CSVC
            v-if="currentUser.user.permission.includes(permission.CO_SO_VAT_CHAT) || currentUser.user.permission.includes(permission.XU_LY_YEU_CAU)"
        ></CSVC>
        <quan-ly-phan-quyen
            v-if="currentUser.user.permission.includes(permission.QUAN_LY_TAI_KHOAN)"
        ></quan-ly-phan-quyen>
        <setting v-if="currentUser.user.permission.includes(permission.QUAN_LY_HE_THONG_CAI_DAT)"></setting>
        <quan-ly-trung-tam-ho-tro
            v-if="currentUser.user.permission.includes(permission.QUAN_LY_TRUNG_TAM_HO_TRO)"
        ></quan-ly-trung-tam-ho-tro>

    </ul>
</template>

<script>
    import {mapGetters} from "vuex";
    import QuanLyNhanSu from './QuanLyNhanSu';
    import QuanLyDonViHanhChinh from "./QuanLyDonViHanhChinh";
    import QuanLyKinhDoanh from './QuanLyKinhDoanh';
    import QuanLyTaiChinh from './QuanLyTaiChinh';
    import QuanLyChuoiCungUng from './QuanLyChuoiCungUng';
    import QuanLyHeThongThongTin from './QuanLyHeThongThongTin';
    import QuanLyDuLieu from './QuanLyDuLieu';
    import CRM from './CRM';
    import QuanLyPhanQuyen from './QuanLyPhanQuyen';
    import Setting from './Setting';
    import QuanLyTrungTamHoTro from './QuanLyTrungTamHoTro';
    import CSVC from "@/view/layout/aside/CSVC";


    export default {
        name: "KTMenu",
        data() {
            return {

            }
        },
        components: {
            CSVC,
            QuanLyNhanSu,
            QuanLyDonViHanhChinh,
            QuanLyKinhDoanh,
            QuanLyTaiChinh,
            QuanLyChuoiCungUng,
            QuanLyHeThongThongTin,
            QuanLyDuLieu,
            CRM,
            Setting,
            QuanLyPhanQuyen,
            QuanLyTrungTamHoTro
        },
        computed: {
            ...mapGetters(["currentUser"]),
        },
        methods: {
            hasActiveChildren(match) {
                return this.$route["path"].indexOf(match) !== -1;
            }
        }
    };
</script>
