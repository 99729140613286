var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[([_vm.role_auth.ADMIN].includes(_vm.currentUser.user.role)||true)?_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
      isActive && 'menu-item-active',
      isExactActive && 'menu-item-active'
    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Trang chủ")])])])]}}],null,false,2404042097)}):_vm._e(),(_vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_NHAN_SU)
         || _vm.currentUser.user.permission.includes(_vm.permission.KHOI_CO_SO)
         || _vm.currentUser.user.permission.includes(_vm.permission.TRU_SO_TONG)
          || _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_NHAN_SU_KHOI_CO_SO)
           || _vm.currentUser.user.permission.includes(_vm.permission.BAO_CAO)
           || _vm.currentUser.user.permission.includes(_vm.permission.XEM_DANH_SACH_NHAN_SU_CO_SO)
           || _vm.currentUser.user.permission.includes(_vm.permission.DIEU_CHUYEN_NHAN_SU)
           || _vm.currentUser.user.permission.includes(_vm.permission.SALE_LEN_CAP)
           || _vm.currentUser.user.permission.includes(_vm.permission.SALE_XUONG_CAP)
           || _vm.currentUser.user.permission.includes(_vm.permission.PHIEU_LUONG)
           || _vm.currentUser.user.permission.includes(_vm.permission.DANH_SACH_DONG_TIEN)
           || _vm.currentUser.user.permission.includes(_vm.permission.WEBSITE_TUYEN_DUNG)
           || _vm.currentUser.user.permission.includes(_vm.permission.SU_KIEN_NOI_BO)
           || _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_DAO_TAO)
           || _vm.currentUser.user.permission.includes(_vm.permission.BAO_CAO_DAO_TAO)
           || _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_DAO_TAO_UNG_VIEN_GIANG_VIEN)
           || _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_DON_VI_TRU_SO_TONG)
           || _vm.currentUser.user.permission.includes(_vm.permission.NHAN_SU_CHAM_CONG)
           || _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_NHAN_SU_TRU_SO_TONG)
           || _vm.currentUser.user.permission.includes(_vm.permission.DON_DE_XUAT)
           || _vm.currentUser.user.permission.includes(_vm.permission.CO_SO_VAT_CHAT_TRU_SO_TONG)
           || _vm.currentUser.user.permission.includes(_vm.permission.PHAN_QUYEN_CHUONG_TRINH)
           || _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_CHAM_CONG)
           || _vm.currentUser.user.permission.includes(_vm.permission.CHUONG_TRINH_TVV_DINH_KY)
           || _vm.currentUser.user.permission.includes(_vm.permission.XEM_DANH_SACH_DONG_TIEN)
           || _vm.currentUser.user.permission.includes(_vm.permission.CHUONG_TRINH_VINH_DANH)
           || _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_VON_GOP_THUC_TE)
           || _vm.currentUser.user.permission.includes(_vm.permission.APP_GAME_CAMBRIDGE)
           || _vm.currentUser.user.permission.includes(_vm.permission.CHI_VAN_PHONG))?_c('quan-ly-nhan-su'):_vm._e(),(_vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_KINH_DOANH)
         || _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_KENH_CTV)
         || _vm.currentUser.user.permission.includes(_vm.permission.BAO_CAO_KENH_CTV)
         || _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_KENH_WEBSITE)
         || _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_GIAO_DIEN)
         || _vm.currentUser.user.permission.includes(_vm.permission.DANH_SACH_TIN_TUC)
         || _vm.currentUser.user.permission.includes(_vm.permission.DANH_SACH_SU_KIEN)
         || _vm.currentUser.user.permission.includes(_vm.permission.DANH_SACH_PAGE)
         || _vm.currentUser.user.permission.includes(_vm.permission.DANH_SACH_PAGE)
         || _vm.currentUser.user.permission.includes(_vm.permission.CHUONG_TRINH_QUAY_THUONG)
         || _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_HE_SO_KINH_DOANH)
        )?_c('quan-ly-kinh-doanh'):_vm._e(),(_vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_TAI_CHINH)
         || _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_TAI_CHINH_2)
         || _vm.currentUser.user.permission.includes(_vm.permission.KHOA_HOC_DANG_KY)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.NAP_RUT_TANG_QUA)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.EDUTALK_THU_TIEN)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.HOP_DONG_UNG)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.NAP_RUT_197)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.BAO_CAO_TAI_CHINH)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.KHACH_HANG_DONG_TIEN)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.DS_KH_DONG_TIEN)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.QUYET_TOAN_TRUNG_TAM)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.CONG_NO_KHACH_HANG)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.XU_LY_SO_DU_TAI_KHOAN)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_CHUYEN_NHUONG)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_DOANH_THU_THUAN)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_CHI_PHI_SACH)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_CHI_PHI_BOX)
        )?_c('quan-ly-tai-chinh'):_vm._e(),(_vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_SAN_XUAT)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_DON_VI_SX)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.TEST_DAU_VAO)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.SU_KIEN)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.WORKSHOP_CLUB)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.DUYET_NGHI_BUOI_HOC)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_DAY_HOC)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.BANG_BAO_LOI)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.SUA_LOI_DAY_HOC)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.BUOI_KIEM_TRA)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.TAC_VU_ADMIN_CA_NHAN)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.LICH_TRUC_TEST_DAU_VAO)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_TAC_VU)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.NHAN_SU_ADMIN)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_LO_TRINH)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.REPORT_CA_TEST)
        )?_c('quan-ly-chuoi-cung-ung'):_vm._e(),(_vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_HE_THONG_THONG_TIN)
        )?_c('quan-ly-he-thong-thong-tin'):_vm._e(),(_vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_DU_LIEU)
        )?_c('quan-ly-du-lieu'):_vm._e(),(_vm.currentUser.user.permission.includes(_vm.permission.CRM)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.KHACH_HANG)
         ||  _vm.currentUser.user.permission.includes(_vm.permission.DS_KHACH_HANG)
         || _vm.currentUser.user.permission.includes(_vm.permission.DS_HD)
         || _vm.currentUser.user.permission.includes(_vm.permission.DS_HD_2)
         || _vm.currentUser.user.permission.includes(_vm.permission.XEM_DS_HD)
         || _vm.currentUser.user.permission.includes(_vm.permission.CHI_TIET_HD)
         || _vm.currentUser.user.permission.includes(_vm.permission.SUA_HD)
         || _vm.currentUser.user.permission.includes(_vm.permission.XOA_HD)
         || _vm.currentUser.user.permission.includes(_vm.permission.TAO_HD)
         || _vm.currentUser.user.permission.includes(_vm.permission.XU_LY_PHAN_HOI)
         || _vm.currentUser.user.permission.includes(_vm.permission.PHONG_BAN_NHAP_GIAI_PHAP)
         || _vm.currentUser.user.permission.includes(_vm.permission.LICH_SU_CUOC_GOI)
         || _vm.currentUser.user.permission.includes(_vm.permission.BAO_CAO_CUOC_GOI)
        )?_c('CRM'):_vm._e(),(_vm.currentUser.user.permission.includes(_vm.permission.CO_SO_VAT_CHAT) || _vm.currentUser.user.permission.includes(_vm.permission.XU_LY_YEU_CAU))?_c('CSVC'):_vm._e(),(_vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_TAI_KHOAN))?_c('quan-ly-phan-quyen'):_vm._e(),(_vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_HE_THONG_CAI_DAT))?_c('setting'):_vm._e(),(_vm.currentUser.user.permission.includes(_vm.permission.QUAN_LY_TRUNG_TAM_HO_TRO))?_c('quan-ly-trung-tam-ho-tro'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }